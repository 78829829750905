<template>
  <div class="text-left bg-lightgray py-4 flex justify-between lg:px-10 px-5">
    <h2 class="text-secondary text-2xl font-medium my-auto">
      Análisis de Restricciones
    </h2>
    <div class="flex">
      <button
        class="
          rounded
          border border-primary
          bg-primary
          py-2
          px-4
          text-white
          font-medium
          flex
        "
        @click="onOpenDrawerUser({ action: 'create' })"
      >
        Nueva Restricción
      </button>
    </div>
  </div>
  <div class="lg:p-10 flex align-start p-5">
    <div class="flex relative">
      <p class="m-auto pr-2 text-gray-400">Filtro:</p>
      <input
        v-model.trim="search"
        class="
          relative
          p-1
          px-3
          lg:w-65
          bg-white
          rounded-sm
          border
          outline-none
          text-gray-700
          placeholder-gray-400
          pr-10
        "
        placeholder="Buscar restricción"
      />
      <span class="absolute inset-y-0 right-0 flex items-center pl-2">
        <button
          type="submit"
          class="p-1 focus:outline-none focus:shadow-outline"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            class="w-8 h-8"
          >
            <path
              d="M23.1022 22.1188L18.4647 17.4813C19.1844 16.5509 19.5737 15.4134 19.5737 14.217C19.5737 12.7849 19.0147 11.442 18.004 10.4295C16.9933 9.41701 15.6469 8.85986 14.2165 8.85986C12.7862 8.85986 11.4397 9.41879 10.429 10.4295C9.41652 11.4402 8.85938 12.7849 8.85938 14.217C8.85938 15.6474 9.4183 16.9938 10.429 18.0045C11.4397 19.017 12.7844 19.5741 14.2165 19.5741C15.4129 19.5741 16.5487 19.1849 17.479 18.467L22.1165 23.1027C22.1301 23.1163 22.1463 23.1271 22.164 23.1345C22.1818 23.1418 22.2009 23.1456 22.2201 23.1456C22.2393 23.1456 22.2584 23.1418 22.2761 23.1345C22.2939 23.1271 22.3101 23.1163 22.3237 23.1027L23.1022 22.3259C23.1158 22.3123 23.1266 22.2962 23.134 22.2784C23.1414 22.2606 23.1452 22.2416 23.1452 22.2224C23.1452 22.2031 23.1414 22.1841 23.134 22.1663C23.1266 22.1485 23.1158 22.1324 23.1022 22.1188ZM17.0451 17.0456C16.2879 17.8009 15.2844 18.217 14.2165 18.217C13.1487 18.217 12.1451 17.8009 11.3879 17.0456C10.6326 16.2884 10.2165 15.2849 10.2165 14.217C10.2165 13.1491 10.6326 12.1438 11.3879 11.3884C12.1451 10.6331 13.1487 10.217 14.2165 10.217C15.2844 10.217 16.2897 10.6313 17.0451 11.3884C17.8004 12.1456 18.2165 13.1491 18.2165 14.217C18.2165 15.2849 17.8004 16.2902 17.0451 17.0456Z"
              fill="#0057FF"
            />
          </svg>
        </button>
      </span>
    </div>
    <div class="flex relative pl-4">
      <p class="m-auto pr-2 text-gray-400">Tipo:</p>
      <select
        v-model="filterTypeKey"
        class="
          relative
          py-2
          px-2
          bg-white
          rounded-sm
          border
          outline-none
          text-gray-700
          placeholder-gray-400
          w-40
        "
        placeholder="Seleccione"
      >
        <option value="">Todos</option>
        <option
          v-for="typeRestriction in getTypesRestriction"
          :key="typeRestriction.key"
          :value="typeRestriction.key"
        >
          {{ typeRestriction.name }}
        </option>
      </select>
    </div>
    <div class="flex relative pl-4">
      <p class="m-auto pr-2 text-gray-400">Responsable:</p>
      <select
        v-model="filterUserKey"
        class="
          relative
          py-2
          px-2
          bg-white
          rounded-sm
          border
          outline-none
          text-gray-700
          placeholder-gray-400
          w-40
        "
        placeholder="Seleccione"
      >
        <option value="">Todos</option>
        <option
          v-for="member in getMembers"
          :key="member.key"
          :value="member.key"
        >
          {{ member.lastName }} {{ member.lastName2 }} {{ member.name }}
        </option>
      </select>
    </div>
    <div class="flex relative pl-4">
      <p class="m-auto pr-2 text-gray-400">Estado:</p>
      <select
        v-model="filterStatus"
        class="
          relative
          py-2
          px-2
          bg-white
          rounded-sm
          border
          outline-none
          text-gray-700
          placeholder-gray-400
          w-40
        "
        placeholder="Seleccione"
      >
        <option value="">Todos</option>
        <option
          v-for="(status, key) in getRestrictionStatus"
          :key="key"
          :value="key"
        >
          {{ status }}
        </option>
      </select>
    </div>
  </div>

  <div v-show="isLoading" class="flex justify-center">
    <img :src="require('@/assets/loader.gif')" width="250" />
  </div>

  <div v-if="!isLoading" class="grid relative w-full lg:px-10 px-5">
    <div class="overflow-x-scroll">
      <table class="w-full">
        <thead>
          <tr class="text-sm font-bold text-secondary">
            <th
              class="px-6 py-2 text-left"
              :style="{ width: '250px !important' }"
            >
              Actividad
            </th>
            <th class="px-6 py-2">Fecha de inicio</th>
            <th
              class="px-6 py-2 text-left"
              :style="{ width: '250px !important' }"
            >
              Restricción
            </th>
            <th class="px-2 py-2 whitespace-nowrap">Responsable</th>
            <th class="px-6 py-2">Levantamiento</th>
            <th class="px-6 py-2">Días restantes</th>
            <th class="px-6 py-2">Estado</th>
            <th class="px-6 py-2">Tipo</th>
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-300">
          <template v-if="getRestrictions">
            <tr v-for="(restriction, i) in getRestrictions" :key="i">
              <td
                class="
                  px-4
                  py-4
                  text-sm text-secondary
                  font-light
                  text-gray-500 text-left
                  flex flex-wrap
                  my-auto
                "
                :style="{ width: '250px !important' }"
              >
                {{ restriction.scheduleName }}
              </td>
              <td
                class="
                  px-4
                  py-4
                  text-sm text-secondary
                  font-light
                  text-gray-500 text-left
                  whitespace-nowrap
                "
              >
                {{ restriction.startDate }}
              </td>
              <td
                class="
                  px-4
                  py-4
                  text-sm text-secondary
                  font-medium
                  text-red-500 text-left
                  flex flex-wrap
                  my-auto
                "
                :style="{ width: '250px !important' }"
              >
                {{ restriction.name }}
              </td>
              <td class="px-2 py-4">
                <div
                  class="
                    text-sm text-left
                    uppercase
                    text-secondary
                    font-light
                    text-gray-900
                    whitespace-nowrap
                  "
                >
                  {{ restriction.user.lastName }}
                  {{ restriction.user.lastName2 }} {{ restriction.user.name }}
                </div>
              </td>
              <td
                class="
                  px-4
                  py-4
                  text-sm text-secondary
                  font-light
                  text-gray-500 text-left
                  whitespace-nowrap
                "
              >
                {{ restriction.liftingDate }}
              </td>
              <td
                class="px-4 py-4 text-sm text-secondary font-medium text-center"
              >
                <span
                  v-if="restriction.status === 'ForLifting'"
                  :class="
                    getDaysAfter(restriction.liftingDate) <= 0
                      ? 'text-red-500'
                      : 'text-green-500'
                  "
                >
                  {{ getDaysAfter(restriction.liftingDate) }}
                </span>
              </td>
              <td
                class="px-4 py-4 text-sm text-secondary font-medium text-center"
                :class="
                  restriction.status === 'ForLifting'
                    ? 'text-red-500'
                    : 'text-green-500'
                "
              >
                {{ getRestrictionStatus[restriction.status] }}
              </td>
              <td
                class="
                  px-4
                  py-4
                  text-sm text-secondary
                  font-light
                  text-gray-500 text-center
                "
              >
                {{ restriction.type.name }}
              </td>
              <td class="px-2 py-4">
                <div class="flex md:justify-end">
                  <a
                    class="
                      text-primary
                      font-bold
                      cursor-pointer
                      hover:bg-blue-50
                      px-2
                    "
                    @click="
                      onOpenDrawerUser({
                        action: 'update',
                        restriction: restriction,
                      })
                    "
                  >
                    Editar
                  </a>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>

  <EndDrawer
    :open="drawerRestriction"
    @update:open="drawerRestriction = $event"
  >
    <RestrictionForm
      v-if="drawerRestriction"
      :action="restrictionFormAction"
      :restriction-selected="restrictionSelected"
      @onComplete="onCloseDrawerRestriction"
    />
  </EndDrawer>
</template>

<script setup>
import { ref, computed, watch, onUnmounted } from "vue";
import { useStore } from "vuex";
import dayjs from "dayjs";
import RestrictionForm from "./forms/Restriction";

const store = useStore();

const isLoading = ref(true);

const search = ref("");
const filterTypeKey = ref("");
const filterUserKey = ref("");
const filterStatus = ref("");
const drawerRestriction = ref(false);
const restrictionFormAction = ref("create");
const restrictionSelected = ref(null);
const matchUsers = ref([]);
const timerToSearch = ref(null);

const getRestrictions = computed(() => store.getters.getRestrictions);
const getTypesRestriction = computed(() => store.getters.getTypesRestriction);
const getRestrictionStatus = computed(() => store.getters.getRestrictionStatus);
const getMembers = computed(() => store.getters.getMembers);

watch(
  () => getMembers.value,
  (currentValue) => {
    matchUsers.value = Array.isArray(currentValue) ? [...currentValue] : [];
  },
  { deep: true }
);

watch(
  () => search.value,
  () => searchUsers()
);

watch(
  () => filterStatus.value,
  () => loadRestrictions()
);

watch(
  () => filterTypeKey.value,
  () => loadRestrictions()
);

watch(
  () => filterUserKey.value,
  () => loadRestrictions()
);

const loadUsers = async () => {
  await store.dispatch("getAllMembers", {
    userEntity: "User",
    type: ["Worker", "Admin"],
  });
  // matchUsers.value = [...getMembers.value];
};

const loadRestrictions = async () => {
  try {
    isLoading.value = true;

    store.dispatch("resetAllRestrictions");

    let filters = {};
    if (
      filterUserKey.value ||
      filterStatus.value ||
      filterTypeKey.value ||
      search.value
    ) {
      filters.userKey = filterUserKey.value;
      filters.status = filterStatus.value;
      filters.typeKey = filterTypeKey.value;
      filters.q = search.value;
    }

    await store.dispatch("getAllRestrictions", filters);
  } catch (error) {
    console.error(error);
  } finally {
    isLoading.value = false;
  }
};

const init = async () => {
  await loadUsers();

  if (!getTypesRestriction.value)
    await store.dispatch("getAllTypes", { type: "Restriction" });

  await loadRestrictions();
};

init();

const searchUsers = async () => {
  clearTimeout(timerToSearch.value);

  timerToSearch.value = setTimeout(async () => {
    loadRestrictions();
  }, 200);
};

const getDaysAfter = (date) => {
  const dateStart = dayjs(date);
  const dateEnd = dayjs(new Date()).format("YYYY-MM-DD");
  const diff = dateStart.diff(dayjs(dateEnd), "day");
  return diff;
};

// const formatDate = (dateString) => {
//   return dayjs(dateString).format("DD/MM/YYYY");
// };

const onOpenDrawerUser = ({ action, restriction = null }) => {
  restrictionSelected.value = { ...restriction };
  drawerRestriction.value = true;
  restrictionFormAction.value = action;
};

const onCloseDrawerRestriction = () => {
  drawerRestriction.value = false;

  if (restrictionFormAction.value === "create") loadRestrictions();
};

onUnmounted(() => {
  store.dispatch("resetMembers");
});
</script>
