<template>
  <Form
    @submit="onSave"
    :validation-schema="{
      scheduleName: 'required',
      name: 'required',
      userKey: 'required',
      typeKey: 'required',
      startDate: 'required',
      liftingDate: 'required',
      status: 'required',
    }"
  >
    <div>
      <h4 class="mb-4 text-secondary">
        {{ action === "create" ? "Crear" : "Editar" }} restricción
      </h4>
    </div>
    <div class="mb-4">
      <div class="relative w-full flex flex-wrap items-stretch">
        <label>Actividad</label>
        <Field v-model="scheduleName" name="scheduleName" v-slot="{ field }">
          <input
            v-bind="field"
            type="text"
            class="
              relative
              py-3
              px-2
              w-full
              bg-white
              rounded
              shadow
              outline-none
              text-gray-700
              placeholder-gray-400
              focus:outline-none focus:shadow-outline
            "
            placeholder="Ingrese actividad"
          />
        </Field>
      </div>
      <div>
        <ErrorMessage name="scheduleName" v-slot="{ message }">
          <p class="text-red-500 pt-1 text-sm">{{ message }}</p>
        </ErrorMessage>
      </div>
    </div>
    <div class="mb-4">
      <div class="relative w-full flex flex-wrap items-stretch">
        <label class="text-dark font-semibold">Fecha de inicio</label>
        <Field v-model="startDate" name="startDate" v-slot="{ field }">
          <input
            v-bind="field"
            type="date"
            class="
              relative
              bg-white
              rounded
              border
              outline-none
              text-gray-700
              placeholder-gray-400
              focus:outline-none
              px-4
              py-2
              w-full
            "
          />
        </Field>
      </div>
      <div>
        <ErrorMessage name="startDate" v-slot="{ message }">
          <p class="text-red-500 pt-1 text-sm">{{ message }}</p>
        </ErrorMessage>
      </div>
    </div>
    <div class="mb-4">
      <div class="relative w-full flex flex-wrap items-stretch">
        <label>Restricción</label>
        <Field v-model="name" name="name" v-slot="{ field }">
          <input
            v-bind="field"
            type="text"
            class="
              relative
              py-3
              px-2
              w-full
              bg-white
              rounded
              shadow
              outline-none
              text-gray-700
              placeholder-gray-400
              focus:outline-none focus:shadow-outline
            "
            placeholder="Ingrese restricción"
          />
        </Field>
      </div>
      <div>
        <ErrorMessage name="name" v-slot="{ message }">
          <p class="text-red-500 pt-1 text-sm">{{ message }}</p>
        </ErrorMessage>
      </div>
    </div>
    <div class="mb-4">
      <div class="relative w-full flex flex-wrap items-stretch">
        <label>Tipo</label>
        <Field v-model="typeKey" name="typeKey" v-slot="{ field }">
          <select
            v-bind="field"
            class="
              relative
              py-3
              px-2
              w-full
              bg-white
              rounded-sm
              border
              outline-none
              text-gray-700
              placeholder-gray-400
            "
            placeholder="Seleccione"
          >
            <option
              v-for="type in getTypesRestriction"
              :key="type.key"
              :value="type.key"
            >
              {{ type.name }}
            </option>
          </select>
        </Field>
        <ErrorMessage name="typeKey" v-slot="{ message }">
          <p class="text-red-500 pt-1 text-sm">{{ message }}</p>
        </ErrorMessage>
      </div>
    </div>
    <div class="mb-4">
      <div class="relative w-full flex flex-wrap items-stretch">
        <label>Responsable</label>
        <Field v-model="userKey" name="userKey" v-slot="{ field }">
          <select
            v-bind="field"
            class="
              relative
              py-3
              px-2
              w-full
              bg-white
              rounded-sm
              border
              outline-none
              text-gray-700
              placeholder-gray-400
            "
            placeholder="Seleccione"
          >
            <option
              v-for="member in getMembers"
              :key="member.key"
              :value="member.key"
            >
              {{ member.lastName }} {{ member.lastName2 }} {{ member.name }}
            </option>
          </select>
        </Field>
      </div>
      <div>
        <ErrorMessage name="userKey" v-slot="{ message }">
          <p class="text-red-500 pt-1 text-sm">{{ message }}</p>
        </ErrorMessage>
      </div>
    </div>
    <div class="mb-4">
      <div class="relative w-full flex flex-wrap items-stretch">
        <label>Fecha de levantamiento</label>
        <Field v-model="liftingDate" name="liftingDate" v-slot="{ field }">
          <input
            v-bind="field"
            type="date"
            class="
              relative
              bg-white
              rounded
              border
              outline-none
              text-gray-700
              placeholder-gray-400
              focus:outline-none
              px-4
              py-2
              w-full
            "
          />
        </Field>
      </div>
      <div>
        <ErrorMessage name="liftingDate" v-slot="{ message }">
          <p class="text-red-500 pt-1 text-sm">{{ message }}</p>
        </ErrorMessage>
      </div>
    </div>
    <div class="mb-4">
      <div class="relative w-full flex flex-wrap items-stretch mb-2">
        <label class="text-dark font-semibold">Estado</label>
      </div>
      <div class="flex items-center mb-3">
        <Field v-model="status" name="status" v-slot="{ field }">
          <input
            v-bind="field"
            id="ForLifting"
            type="radio"
            value="ForLifting"
            :checked="status === 'ForLifting'"
            class="
              w-4
              h-4
              text-blue-600
              bg-gray-100
              border-gray-300
              dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600
            "
          />
        </Field>
        <label
          for="ForLifting"
          class="ml-2 text-sm text-gray-900 dark:text-gray-300"
          >Por levantar</label
        >
      </div>
      <div class="flex items-center">
        <Field v-model="status" name="status" v-slot="{ field }">
          <input
            v-bind="field"
            v-model="status"
            id="Raised"
            type="radio"
            value="Raised"
            :checked="status === 'Raised'"
            class="
              w-4
              h-4
              text-blue-600
              bg-gray-100
              border-gray-300
              dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600
            "
          />
        </Field>
        <label
          for="Raised"
          class="ml-2 text-sm text-gray-900 dark:text-gray-300"
          >Levantada</label
        >
      </div>
      <div>
        <ErrorMessage name="status" v-slot="{ message }">
          <p class="text-red-500 pt-1 text-sm">{{ message }}</p>
        </ErrorMessage>
      </div>
    </div>

    <Alert
      v-if="responseMessage"
      :type="responseType"
      :text="responseMessage"
    ></Alert>

    <div>
      <button
        class="
          rounded
          bg-primary
          py-2
          px-4
          text-white
          w-full
          flex
          justify-center
        "
        type="submit"
        :disabled="loadingSave"
      >
        <LoadingButtonIcon v-if="loadingSave" />
        Guardar
      </button>
    </div>
  </Form>
</template>

<script setup>
import { defineProps, defineEmits, computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { useField } from "vee-validate";

const props = defineProps({
  action: {
    type: String,
    default: "create",
  },
  restrictionSelected: {
    type: Object,
    default: () => {},
  },
});
const emits = defineEmits(["onComplete"]);
const store = useStore();

const { value: userKey } = useField("userKey");
const { value: scheduleName } = useField("scheduleName");
const { value: name } = useField("name");
const { value: startDate } = useField("startDate");
const { value: liftingDate } = useField("liftingDate");
const { value: typeKey } = useField("typeKey");
const { value: status } = useField("status");

const user = ref("");
const userSelected = ref(null);
const matchUsers = ref([]);
const responseType = ref(null);
const responseMessage = ref(null);
const loadingSave = ref(false);

const getTypesRestriction = computed(() => store.getters.getTypesRestriction);
const getMembers = computed(() => store.getters.getMembers);

watch(
  () => user.value,
  (currentValue, oldValue) => {
    if (!userSelected.value && currentValue !== oldValue)
      userSelected.value = null;
  }
);

const init = async () => {
  matchUsers.value = [...getMembers.value];

  if (props.action === "update") {
    const restrictionUser = props.restrictionSelected.user;
    userKey.value = restrictionUser.key;
    user.value = `${restrictionUser.lastName} ${restrictionUser.lastName2} ${restrictionUser.name}`;
    userSelected.value = restrictionUser.key;

    name.value = props.restrictionSelected.name;
    scheduleName.value = props.restrictionSelected.scheduleName;
    startDate.value = props.restrictionSelected.startDate;
    liftingDate.value = props.restrictionSelected.liftingDate;
    typeKey.value = props.restrictionSelected.type?.key;
    status.value = props.restrictionSelected.status;
  }
};

init();

const onSave = async (values, { resetForm }) => {
  try {
    loadingSave.value = true;
    responseType.value = null;
    responseMessage.value = null;

    if (props.action === "create")
      await store.dispatch("createRestriction", {
        restriction: values,
      });
    else
      await store.dispatch("updateRestriction", {
        restriction: {
          ...values,
          key: props.restrictionSelected.key,
        },
      });

    resetForm();
    emits("onComplete");
  } catch (error) {
    responseType.value = "error";
    responseMessage.value =
      error.message || "Ocurrió un error al guardar la información";

    console.error(error);
  } finally {
    loadingSave.value = false;
  }
};
</script>
